<template>
  <div>
    <contract-approve-popup-follower
      :is-popup-follower-active.sync="isPopupFollowerActive"
      @select-item="fncSelectFollower"
    />
    <b-modal
      ref="refModalDatePickerEffStrDate"
      centered
      title="วันที่เริ่มต้น"
      hide-footer
      size="sm"
      modal-class="modalDatePicker"
    >
      <v-date-picker
        v-model="getInputEffStrDate"
        class="mt-6"
        locale="th"
        @input="refModalDatePickerEffStrDate.hide()"
      />
    </b-modal>
    <b-modal
      ref="refModalConfirmSubmit"
      centered
      title="ยืนยันการทำรายการ"
      @hidden="isPopupConfirmSubmitActive = false"
    >
      <h3>{{ resolvePopupSubmitTypeTitle }}</h3>
      <template v-if="checkSubmitType != 'approve'">
        <b-form-textarea
          v-model="getTextareaSubmitRemark"
        />
      </template>
      <template #modal-footer>
        <div class="w-100">
          <b-button
            v-ripple.400="'rgba(30, 30, 30, 0.15)'"
            class="float-left"
            variant="primary"
            @click="$emit('fnc-click-submit', checkSubmitType);refModalConfirmSubmit.hide();"
          >
            ยืนยัน
          </b-button>
          <b-button
            v-ripple.400="'rgba(30, 30, 30, 0.15)'"
            class="float-left ml-1"
            variant="outline-dark"
            @click="refModalConfirmSubmit.hide()"
          >
            ยกเลิก
          </b-button>
        </div>
      </template>
    </b-modal>
    <b-card>

      <b-row>
        <b-col :cols="12">
          <hr>
          <h3>เงื่อนไขการเช่า</h3>
        </b-col>
      </b-row>

      <b-row>
        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label-for=""
            label-cols-md="3"
            label-cols-sm="12"
          >
            <template v-slot:label>
              <div style="margin-top:10px;">
                วันที่เริ่มต้น
              </div>
            </template>
            <b-form-input
              id="InputEffStrDate"
              :value="resolveFormatDate(inputEffStrDate)"
              trim
              readonly
              placeholder="วันที่เริ่มต้น"
              disabled
              @click="fncShowDatepickerEffStrDate()"
            />
          </b-form-group>
        </b-col>

        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label-for="inputEffEndDate"
            label-cols-md="3"
            label-cols-sm="12"
          >
            <template v-slot:label>
              <div style="margin-top:10px;">
                วันที่สิ้นสุด
              </div>
            </template>
            <b-form-input
              id="inputEffEndDate"
              :value="resolveFormatDate(getInputEffEndDate)"
              trim
              readonly
              disabled
              placeholder="วันที่สิ้นสุด"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label-for=""
            label-cols-md="3"
            label-cols-sm="12"
          >
            <template v-slot:label>
              <div style="margin-top:10px;">
                ระยะเวลา
              </div>
            </template>
            <b-form-input
              id="inputCndRentYear"
              v-model="getInputCndRentYear"
              placeholder="ระยะเวลา ปี"
              trim
              style="display: inline;width: 40%; margin-left: 5px;"
              disabled
            /> ปี
            <b-form-input
              id="inputCndRentMonth"
              v-model="getInputCndRentMonth"
              placeholder="ระยะเวลา เดือน"
              trim
              style="display: inline;width: 40%;  margin-left: 5px;"
              disabled
            /> เดือน
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label="ค่าเช่าปีที่ 1"
            label-for="CndRentAmt1"
            label-cols-md="3"
            label-cols-sm="12"
          >
            <template v-slot:label>
              <div style="margin-top:10px;">
                ค่าเช่าปีที่ 1
              </div>
            </template>
            <b-form-input
              id="CndRentAmt1"
              v-model="getInputCndRentAmt1"
              placeholder="ค่าเช่าปีที่ 1"
              trim
              autocomplete="off"
              disabled
            />

          </b-form-group>
        </b-col>

        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label="ค่าเช่าปีที่ 2"
            label-for="CndRentAmt2"
            label-cols-md="3"
            label-cols-sm="12"
          >
            <template v-slot:label>
              <div style="margin-top:10px;">
                ค่าเช่าปีที่ 2
              </div>
            </template>
            <b-form-input
              id="CndRentAmt2"
              v-model="getInputCndRentAmt2"
              placeholder="ค่าเช่าปีที่ 2"
              trim
              autocomplete="off"
              disabled
            />

          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label="ค่าเช่าปีที่ 3"
            label-for="CndRentAmt3"
            label-cols-md="3"
            label-cols-sm="12"
          >
            <template v-slot:label>
              <div style="margin-top:10px;">
                ค่าเช่าปีที่ 3
              </div>
            </template>
            <b-form-input
              id="CndRentAmt3"
              v-model="getInputCndRentAmt3"
              placeholder="ค่าเช่าปีที่ 3"
              trim
              autocomplete="off"
              disabled
            />

          </b-form-group>
        </b-col>

        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label="ค่าเช่าปีที่ 4"
            label-for="CndRentAmt4"
            label-cols-md="3"
            label-cols-sm="12"
          >
            <template v-slot:label>
              <div style="margin-top:10px;">
                ค่าเช่าปีที่ 4
              </div>
            </template>
            <b-form-input
              id="CndRentAmt4"
              v-model="getInputCndRentAmt4"
              placeholder="ค่าเช่าปีที่ 4"
              trim
              autocomplete="off"
              disabled
            />

          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label="ค่าเช่าปีที่ 5"
            label-for="CndRentAmt5"
            label-cols-md="3"
            label-cols-sm="12"
          >
            <template v-slot:label>
              <div style="margin-top:10px;">
                ค่าเช่าปีที่ 5
              </div>
            </template>
            <b-form-input
              id="CndRentAmt1"
              v-model="getInputCndRentAmt5"
              placeholder="ค่าเช่าปีที่ 5"
              trim
              autocomplete="off"
              disabled
            />

          </b-form-group>
        </b-col>

        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label="ค่าเช่าปีที่ 6"
            label-for="CndRentAmt6"
            label-cols-md="3"
            label-cols-sm="12"
          >
            <template v-slot:label>
              <div style="margin-top:10px;">
                ค่าเช่าปีที่ 6
              </div>
            </template>
            <b-form-input
              id="CndRentAmt6"
              v-model="getInputCndRentAmt6"
              placeholder="ค่าเช่าปีที่ 6"
              trim
              autocomplete="off"
              disabled
            />

          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label="ค่าเช่าปีที่ 7"
            label-for="CndRentAmt7"
            label-cols-md="3"
            label-cols-sm="12"
          >
            <template v-slot:label>
              <div style="margin-top:10px;">
                ค่าเช่าปีที่ 7
              </div>
            </template>
            <b-form-input
              id="CndRentAmt7"
              v-model="getInputCndRentAmt7"
              placeholder="ค่าเช่าปีที่ 7"
              trim
              autocomplete="off"
              disabled
            />

          </b-form-group>
        </b-col>

        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label="ค่าเช่าปีที่ 8"
            label-for="CndRentAmt8"
            label-cols-md="3"
            label-cols-sm="12"
          >
            <template v-slot:label>
              <div style="margin-top:10px;">
                ค่าเช่าปีที่ 8
              </div>
            </template>
            <b-form-input
              id="CndRentAmt8"
              v-model="getInputCndRentAmt8"
              placeholder="ค่าเช่าปีที่ 8"
              trim
              autocomplete="off"
              disabled
            />

          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label="ค่าเช่าปีที่ 9"
            label-for="CndRentAmt9"
            label-cols-md="3"
            label-cols-sm="12"
          >
            <template v-slot:label>
              <div style="margin-top:10px;">
                ค่าเช่าปีที่ 9
              </div>
            </template>
            <b-form-input
              id="CndRentAmt9"
              v-model="getInputCndRentAmt9"
              placeholder="ค่าเช่าปีที่ 9"
              trim
              autocomplete="off"
              disabled
            />

          </b-form-group>
        </b-col>

        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label="ค่าเช่าปีที่ 10"
            label-for="CndRentAmt10"
            label-cols-md="3"
            label-cols-sm="12"
          >
            <template v-slot:label>
              <div style="margin-top:10px;">
                ค่าเช่าปีที่ 10
              </div>
            </template>
            <b-form-input
              id="CndRentAmt10"
              v-model="getInputCndRentAmt10"
              placeholder="ค่าเช่าปีที่ 10"
              trim
              autocomplete="off"
              disabled
            />

          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label="ค่าคอมมิชชั่น"
            label-for="CommAmount"
            label-cols-md="3"
            label-cols-sm="12"
          >
            <template v-slot:label>
              <div style="margin-top:10px;">
                ค่าคอมมิชชั่น
              </div>
            </template>
            <b-form-input
              id="CommAmount"
              v-model="getInputCommAmount"
              placeholder="ค่าคอมมิชชั่น"
              trim
              autocomplete="off"
              disabled
            />

          </b-form-group>
        </b-col>
        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label="จัดเก็บไปก่อน"
            label-for="TempRent"
            label-cols-md="3"
            label-cols-sm="12"
          >
            <template v-slot:label>
              <div style="margin-top:10px;">
                จัดเก็บไปก่อน
              </div>
            </template>
            <b-form-input
              id="TempRent"
              v-model="getInputTempRent"
              placeholder="จัดเก็บไปก่อน"
              trim
              autocomplete="off"
            />

          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col :cols="12">
          <hr>
          <h3>เงื่อนไขเพิ่มเติม</h3>
        </b-col>
      </b-row>

      <b-row>
        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label="เพิ่มเติม 1"
            label-for="CndReMark1"
            label-cols-md="3"
            label-cols-sm="12"
          >
            <template v-slot:label>
              <div style="margin-top:10px;">
                เพิ่มเติม 1
              </div>
            </template>
            <b-form-input
              id="CndReMark1"
              v-model="getInputCndReMark1"
              placeholder="เพิ่มเติม 1"
              trim
              autocomplete="off"
            />

          </b-form-group>
        </b-col>

        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label="เพิ่มเติม 2"
            label-for="CndReMark2"
            label-cols-md="3"
            label-cols-sm="12"
          >
            <template v-slot:label>
              <div style="margin-top:10px;">
                เพิ่มเติม 2
              </div>
            </template>
            <b-form-input
              id="CndReMark2"
              v-model="getInputCndReMark2"
              placeholder="เพิ่มเติม 2"
              trim
              autocomplete="off"
            />

          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label="เพิ่มเติม 3"
            label-for="CndReMark3"
            label-cols-md="3"
            label-cols-sm="12"
          >
            <template v-slot:label>
              <div style="margin-top:10px;">
                เพิ่มเติม 3
              </div>
            </template>
            <b-form-input
              id="CndReMark3"
              v-model="getInputCndReMark3"
              placeholder="เพิ่มเติม 3"
              trim
              autocomplete="off"
            />

          </b-form-group>
        </b-col>

        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label="เพิ่มเติม 4"
            label-for="CndReMark4"
            label-cols-md="3"
            label-cols-sm="12"
          >
            <template v-slot:label>
              <div style="margin-top:10px;">
                เพิ่มเติม 4
              </div>
            </template>
            <b-form-input
              id="CndReMark4"
              v-model="getInputCndReMark4"
              placeholder="เพิ่มเติม 4"
              trim
              autocomplete="off"
            />

          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label="เพิ่มเติม 5"
            label-for="CndReMark5"
            label-cols-md="3"
            label-cols-sm="12"
          >
            <template v-slot:label>
              <div style="margin-top:10px;">
                เพิ่มเติม 5
              </div>
            </template>
            <b-form-input
              id="CndReMark5"
              v-model="getInputCndReMark5"
              placeholder="เพิ่มเติม 5"
              trim
              autocomplete="off"
            />

          </b-form-group>
        </b-col>

        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label="เพิ่มเติม 6"
            label-for="CndReMark6"
            label-cols-md="3"
            label-cols-sm="12"
          >
            <template v-slot:label>
              <div style="margin-top:10px;">
                เพิ่มเติม 6
              </div>
            </template>
            <b-form-input
              id="CndReMark6"
              v-model="getInputCndReMark6"
              placeholder="เพิ่มเติม 6"
              trim
              autocomplete="off"
            />

          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label="เพิ่มเติม 7"
            label-for="CndReMark7"
            label-cols-md="3"
            label-cols-sm="12"
          >
            <template v-slot:label>
              <div style="margin-top:10px;">
                เพิ่มเติม 7
              </div>
            </template>
            <b-form-input
              id="CndReMark7"
              v-model="getInputCndReMark7"
              placeholder="เพิ่มเติม 7"
              trim
              autocomplete="off"
            />

          </b-form-group>
        </b-col>

        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label="เพิ่มเติม 8"
            label-for="CndReMark8"
            label-cols-md="3"
            label-cols-sm="12"
          >
            <template v-slot:label>
              <div style="margin-top:10px;">
                เพิ่มเติม 8
              </div>
            </template>
            <b-form-input
              id="CndReMark8"
              v-model="getInputCndReMark8"
              placeholder="เพิ่มเติม 8"
              trim
              autocomplete="off"
            />

          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label="เพิ่มเติม 9"
            label-for="CndReMark9"
            label-cols-md="3"
            label-cols-sm="12"
          >
            <template v-slot:label>
              <div style="margin-top:10px;">
                เพิ่มเติม 9
              </div>
            </template>
            <b-form-input
              id="CndReMark9"
              v-model="getInputCndReMark9"
              placeholder="เพิ่มเติม 9"
              trim
              autocomplete="off"
            />

          </b-form-group>
        </b-col>

        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label="เพิ่มเติม 10"
            label-for="CndReMark10"
            label-cols-md="3"
            label-cols-sm="12"
          >
            <template v-slot:label>
              <div style="margin-top:10px;">
                เพิ่มเติม 10
              </div>
            </template>
            <b-form-input
              id="CndReMark10"
              v-model="getInputCndReMark10"
              placeholder="เพิ่มเติม 10"
              trim
              autocomplete="off"
            />

          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="6">
          <b-form-group
            label="เอกสารแนบ"
            label-for="CndReMark10"
            label-cols-md="3"
            label-cols-sm="12"
          >
            <b-form-input
              :value="Object.keys(getFileName) ? getFileName.name : null"
              placeholder="เอกสารแนบ"
              trim
              autocomplete="off"
              disabled
            />
            <b-form-file
              id="profile-image"
              ref="refUploadFile"
              v-model="getInputFileName"
              style="display: none;"
              class="mt-1"
              browse-text="เปลี่ยนภาพ"
              @change="fncOnFileChange"
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
            label-for="btnUpload"
            label-cols-md="1"
            label-cols-sm="12"
          >
            <b-button
              variant="primary"
              class="mr-1"
              @click="fncClickUpload()"
            >
              Upload
            </b-button>
            <b-button
              variant="warning"
              class="mr-1"
            >
              View
            </b-button>
            <b-button
              variant="success"
              class="mr-1"
            >
              Download
            </b-button>
            <b-button
              variant="danger"
              @click="fncRemoveFile"
            >
              Delete
            </b-button>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row class="delete-margin">
        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label-for="follower"
            label-cols-md="3"
            label-cols-sm="12"
          >
            <template v-slot:label>
              <div style="margin-top:10px;">
                ผู้ดูแล
              </div>
            </template>
            <b-input-group v-show="!inputSearchFollower">
              <b-form-input
                id="inputSearchFollower"
                v-model="inputSearchFollower"
                placeholder="ค้นหาผู้ดูแล"
                trim
                @keydown.enter.native="
                  fncSearchfollower(inputSearchfollower)
                "
              />
              <b-input-group-append>
                <b-button
                  variant="primary"
                  @click="fncOpenPopupFollower()"
                >
                  <feather-icon icon="ListIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <b-input-group v-show="inputSearchFollower">
              <b-form-input
                id="inputSearchFollower"
                :value="inputSearchFollower"
                trim
                disabled
              />
              <b-input-group-append>
                <b-button
                  variant="primary"
                  @click="fncCancelFollower"
                >
                  <feather-icon icon="XIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <div class="d-flex align-items-center justify-content-end">
            <b-button
              variant="primary"
              @click="fncTabPrev()"
            >
              ย้อนกลับ
            </b-button>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label-for=""
            label-cols-md="3"
            label-cols-sm="12"
          >
            <div
              class="d-inline-block"
              :class="resolveFormType == 'add' ? 'ml-1' : null"
            >
              <b-overlay
                :show="overlaySubmitButton"
                rounded
                opacity="0.6"
                spinner-small
                spinner-variant="primary"
                class="d-inline-block"
              >
                <b-button
                  variant="primary"
                  @click="fncClickSubmit('approve')"
                >
                  อนุมัติ
                </b-button>
              </b-overlay>
              <b-overlay
                :show="overlaySubmitButton"
                rounded
                opacity="0.6"
                spinner-small
                spinner-variant="primary"
                class="d-inline-block ml-1"
              >
                <b-button
                  variant="primary"
                  @click="fncClickSubmit('reject')"
                >
                  ไม่อนุมัติ
                </b-button>
              </b-overlay>
              <b-overlay
                :show="overlaySubmitButton"
                rounded
                opacity="0.6"
                spinner-small
                spinner-variant="primary"
                class="d-inline-block ml-1"
              >
                <b-button
                  variant="primary"
                  @click="fncClickSubmit('return')"
                >
                  ส่งกลับ
                </b-button>
              </b-overlay>
            </div>
            <b-button
              class="ml-1"
              variant="outline-secondary"
              :to="{ name: 'contract-approve' }"
            >
              ยกเลิก
            </b-button>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormInput, BButton, BFormGroup, BInputGroup, BInputGroupAppend, BOverlay, BCard, BFormFile, BFormTextarea,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {
  ref, computed, watch,
} from '@vue/composition-api'
import store from '@/store'
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment'
import {
  VDatePicker,
} from 'vuetify/lib'
import ContractApproveStoreModule from './ContractApproveStoreModule'
import ContractApprovePopupFollower from './contract-approve-popup/ContractApprovePopupFollower.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BButton,
    BInputGroup,
    BInputGroupAppend,
    BOverlay,
    BCard,
    VDatePicker,
    BFormFile,
    BFormTextarea,
    ContractApprovePopupFollower,
  },

  directives: {
    Ripple,
  },

  props: {
    tabIndex: {
      type: Number,
      required: true,
    },

    resolveFormType: {
      type: String,
      default: null,
    },

    inputCndReMark1: {
      type: String,
      default: null,
    },

    inputCndReMark2: {
      type: String,
      default: null,
    },

    inputCndReMark3: {
      type: String,
      default: null,
    },

    inputCndReMark4: {
      type: String,
      default: null,
    },

    inputCndReMark5: {
      type: String,
      default: null,
    },

    inputCndReMark6: {
      type: String,
      default: null,
    },

    inputCndReMark7: {
      type: String,
      default: null,
    },

    inputCndReMark8: {
      type: String,
      default: null,
    },

    inputCndReMark9: {
      type: String,
      default: null,
    },

    inputCndReMark10: {
      type: String,
      default: null,
    },

    inputSearchFollower: {
      type: String,
      default: null,
    },

    // -----fromtab1-----
    inputRegNum1: {
      type: String,
      default: null,
    },

    inputRegNum2: {
      type: String,
      default: null,
    },

    chassisNum: {
      type: String,
      default: null,
    },

    inputEffStrDate: {
      type: String,
      default: null,
    },

    inputEffEndDate: {
      type: String,
      default: null,
    },

    inputCndRentYear: {
      type: [String, Number],
      default: null,
    },

    inputCndRentMonth: {
      type: [String, Number],
      default: null,
    },

    inputCndRentAmt1: {
      type: [String, Number],
      default: null,
    },

    inputCndRentAmt2: {
      type: [String, Number],
      default: null,
    },

    inputCndRentAmt3: {
      type: [String, Number],
      default: null,
    },

    inputCndRentAmt4: {
      type: [String, Number],
      default: null,
    },

    inputCndRentAmt5: {
      type: [String, Number],
      default: null,
    },

    inputCndRentAmt6: {
      type: [String, Number],
      default: null,
    },

    inputCndRentAmt7: {
      type: [String, Number],
      default: null,
    },

    inputCndRentAmt8: {
      type: [String, Number],
      default: null,
    },

    inputCndRentAmt9: {
      type: [String, Number],
      default: null,
    },

    inputCndRentAmt10: {
      type: [String, Number],
      default: null,
    },

    inputCommAmount: {
      type: [String, Number],
      default: null,
    },

    inputTempRent: {
      type: [String, Number],
      default: null,
    },

    inputProfileImage: {
      type: File,
      default: null,
    },

    urlFileName: {
      type: String,
      default: null,
    },

    fileName: {
      type: [File, Object],
      default: null,
    },

    textareaSubmitRemark: {
      type: String,
      default: null,
    },
    // -----fromtab2-----
  },

  setup(props, { emit }) {
    const APP_STORE_MODULE_NAME = 'appContractApprove'

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, ContractApproveStoreModule)

    // ======================= 2 WAY BINDING PROPS =======================
    const getInputCndReMark1 = computed({
      get() {
        return props.inputCndReMark1
      },
      set(val) {
        emit('update:input-cnd-re-mark1', val)
      },
    })

    const getInputCndReMark2 = computed({
      get() {
        return props.inputCndReMark2
      },
      set(val) {
        emit('update:input-cnd-re-mark2', val)
      },
    })

    const getInputCndReMark3 = computed({
      get() {
        return props.inputCndReMark3
      },
      set(val) {
        emit('update:input-cnd-re-mark3', val)
      },
    })

    const getInputCndReMark4 = computed({
      get() {
        return props.inputCndReMark4
      },
      set(val) {
        emit('update:input-cnd-re-mark4', val)
      },
    })

    const getInputCndReMark5 = computed({
      get() {
        return props.inputCndReMark5
      },
      set(val) {
        emit('update:input-cnd-re-mark5', val)
      },
    })

    const getInputCndReMark6 = computed({
      get() {
        return props.inputCndReMark6
      },
      set(val) {
        emit('update:input-cnd-re-mark6', val)
      },
    })

    const getInputCndReMark7 = computed({
      get() {
        return props.inputCndReMark7
      },
      set(val) {
        emit('update:input-cnd-re-mark7', val)
      },
    })

    const getInputCndReMark8 = computed({
      get() {
        return props.inputCndReMark8
      },
      set(val) {
        emit('update:input-cnd-re-mark8', val)
      },
    })

    const getInputCndReMark9 = computed({
      get() {
        return props.inputCndReMark9
      },
      set(val) {
        emit('update:input-cnd-re-mark9', val)
      },
    })

    const getInputCndReMark10 = computed({
      get() {
        return props.inputCndReMark10
      },
      set(val) {
        emit('update:input-cnd-re-mark10', val)
      },
    })

    const getInputSearchFollower = computed({
      get() {
        return props.inputSearchFollower
      },
      set(val) {
        emit('update:input-search-follower', val)
      },
    })

    const getTextareaSubmitRemark = computed({
      get() {
        return props.textareaSubmitRemark
      },
      set(val) {
        emit('update:textarea-submit-remark', val)
      },
    })

    // --------------------------fromtab1--------------------------

    const getInputRegNum1 = computed({
      get() {
        return props.inputRegNum1
      },
      set(val) {
        emit('update:input-reg-num1', val)
      },
    })

    const getInputRegNum2 = computed({
      get() {
        return props.inputRegNum2
      },
      set(val) {
        emit('update:input-reg-num2', val)
      },
    })

    const getChassisNum = computed({
      get() {
        return props.chassisNum
      },
      set(val) {
        emit('update:chassis-num', val)
      },
    })
    // --------------------------fromtab1--------------------------

    const getInputEffStrDate = computed({
      get() {
        return props.inputEffStrDate
      },
      set(val) {
        emit('update:input-eff-str-date', val)
        // eslint-disable-next-line no-use-before-define
        fncEffdate(val)
        console.log(val)
      },
    })

    const getInputEffEndDate = computed({
      get() {
        return props.inputEffEndDate
      },
      set(val) {
        console.log(val)
        emit('update:input-eff-end-date', val)
      },
    })

    const getInputCndRentYear = computed({
      get() {
        return props.inputCndRentYear
      },
      set(val) {
        emit('update:input-cnd-rent-year', val)
      },
    })

    const getInputCndRentMonth = computed({
      get() {
        return props.inputCndRentMonth
      },
      set(val) {
        emit('update:input-cnd-rent-month', val)
      },
    })

    const getInputCndRentAmt1 = computed({
      get() {
        return props.inputCndRentAmt1
      },
      set(val) {
        emit('update:input-cnd-rent-amt1', val)
      },
    })

    const getInputCndRentAmt2 = computed({
      get() {
        return props.inputCndRentAmt2
      },
      set(val) {
        emit('update:input-cnd-rent-amt2', val)
      },
    })

    const getInputCndRentAmt3 = computed({
      get() {
        return props.inputCndRentAmt3
      },
      set(val) {
        emit('update:input-cnd-rent-amt3', val)
      },
    })

    const getInputCndRentAmt4 = computed({
      get() {
        return props.inputCndRentAmt4
      },
      set(val) {
        emit('update:input-cnd-rent-amt4', val)
      },
    })

    const getInputCndRentAmt5 = computed({
      get() {
        return props.inputCndRentAmt5
      },
      set(val) {
        emit('update:input-cnd-rent-amt5', val)
      },
    })

    const getInputCndRentAmt6 = computed({
      get() {
        return props.inputCndRentAmt6
      },
      set(val) {
        emit('update:input-cnd-rent-amt6', val)
      },
    })

    const getInputCndRentAmt7 = computed({
      get() {
        return props.inputCndRentAmt7
      },
      set(val) {
        emit('update:input-cnd-rent-amt7', val)
      },
    })

    const getInputCndRentAmt8 = computed({
      get() {
        return props.inputCndRentAmt8
      },
      set(val) {
        emit('update:input-cnd-rent-amt8', val)
      },
    })

    const getInputCndRentAmt9 = computed({
      get() {
        return props.inputCndRentAmt9
      },
      set(val) {
        emit('update:input-cnd-rent-amt9', val)
      },
    })

    const getInputCndRentAmt10 = computed({
      get() {
        return props.inputCndRentAmt10
      },
      set(val) {
        emit('update:input-cnd-rent-amt10', val)
      },
    })

    const getInputCommAmount = computed({
      get() {
        return props.inputCommAmount
      },
      set(val) {
        emit('update:input-comm-amount', val)
      },
    })

    const getInputTempRent = computed({
      get() {
        return props.inputTempRent
      },
      set(val) {
        emit('update:input-temp-rent', val)
      },
    })

    const getInputFileName = computed({
      get() {
        return props.inputProfileImage
      },
      set(val) {
        emit('update:input-file-name', val)
      },
    })

    const getUrlFileName = computed({
      get() {
        return props.urlFileName
      },
      set(val) {
        emit('update:url-file-name', val)
      },
    })

    const getFileName = computed({
      get() {
        return props.fileName
      },
      set(val) {
        emit('update:file-name', val)
      },
    })

    // --------------------------fromtab2--------------------------
    // ตัวแปรกด Tab ถัดไป
    const fncTabPrev = () => {
      emit('update:tab-index', Number(props.tabIndex) - 1)
    }

    // ประกาศตัวแปรสำหรับเก็บค่า
    const tempSearchFollower = ref(null)

    // ประกาศตัวแปร Active / Inactive
    const isPopupFollowerActive = ref(false)
    const isPopupConfirmSubmitActive = ref(false)

    // ประกาศตัวแปร textbox
    const followerName = ref(false)

    // ประกาศตัวแปรสำหรับเก็บค่า
    const isChangeProductImage = ref(false)
    const checkSubmitType = ref(null)

    /* ฟังก์ชั่นเมื่อมีการกดเลือกผู้ดูแลจาก Popup */
    const fncSelectFollower = data => {
      getInputSearchFollower.value = data.acccodfol
    }

    /* ฟังก์ชั่นยกเลิกผู้ดูแล */
    const fncCancelFollower = () => {
      getInputSearchFollower.value = null
    }

    /* ฟังก์ชั่นเปิด Popup เลขทะเบียน */
    const fncOpenPopupFollower = search => {
      if (search) tempSearchFollower.value = search
      else tempSearchFollower.value = null

      isPopupFollowerActive.value = true
    }

    // ประกาศตัวแปร Ref
    const refModalDatePickerEffStrDate = ref(null)
    const refModalConfirmSubmit = ref(null)
    const refUploadFile = ref(null)

    /* ฟังก์ชั่นแสดง DatePicker วันเกิด */
    const fncShowDatepickerEffStrDate = () => refModalDatePickerEffStrDate.value.show()

    const accesstime = new Date()

    const fncEffdate = getDate => {
      const payloadUpdrentCondition = {
        action: 'VIEW',
        events: 'RETRIEVE',
        function: 'GET',
        username: JSON.parse(localStorage.getItem('userData')).member_no,
        access_time: moment(accesstime.value).format('YYYY-MM-DD'),
        regnum1_in: getInputRegNum1.value,
        regnum2_str_in: getInputRegNum2.value,
        regnum2_end_in: null,
        effdte_in: getDate,
      }
      // console.log(payloadUpdrentCondition)

      store
        .dispatch(`${APP_STORE_MODULE_NAME}/SP_RT_UPD_RENT_CONDITION`, payloadUpdrentCondition)
        .then(response => {
          // console.log(response)
          // getInputEffStrDate.value = response.data.responseData[0].effdte_str

          if (response.data.responseData.length) {
            getInputEffEndDate.value = response.data.responseData[0].effdte_exp
            getInputCndRentYear.value = response.data.responseData[0].cnd_rent_year
            getInputCndRentMonth.value = response.data.responseData[0].cnd_rent_month
            getInputCndRentAmt1.value = response.data.responseData[0].cnd_rent_amt1
            getInputCndRentAmt2.value = response.data.responseData[0].cnd_rent_amt2
            getInputCndRentAmt3.value = response.data.responseData[0].cnd_rent_amt3
            getInputCndRentAmt4.value = response.data.responseData[0].cnd_rent_amt4
            getInputCndRentAmt5.value = response.data.responseData[0].cnd_rent_amt5
            getInputCndRentAmt6.value = response.data.responseData[0].cnd_rent_amt6
            getInputCndRentAmt7.value = response.data.responseData[0].cnd_rent_amt7
            getInputCndRentAmt8.value = response.data.responseData[0].cnd_rent_amt8
            getInputCndRentAmt9.value = response.data.responseData[0].cnd_rent_amt9
            getInputCndRentAmt10.value = response.data.responseData[0].cnd_rent_amt10
            getInputCommAmount.value = response.data.responseData[0].commission_amount
            getInputTempRent.value = response.data.responseData[0].temp_rent
            getInputCndReMark1.value = response.data.responseData[0].cnd_remark1
            getInputCndReMark2.value = response.data.responseData[0].cnd_remark2
            getInputCndReMark3.value = response.data.responseData[0].cnd_remark3
            getInputCndReMark4.value = response.data.responseData[0].cnd_remark4
            getInputCndReMark5.value = response.data.responseData[0].cnd_remark5
            getInputCndReMark6.value = response.data.responseData[0].cnd_remark6
            getInputCndReMark7.value = response.data.responseData[0].cnd_remark7
            getInputCndReMark8.value = response.data.responseData[0].cnd_remark8
            getInputCndReMark9.value = response.data.responseData[0].cnd_remark9
            getInputCndReMark10.value = response.data.responseData[0].cnd_remark10
          }
        })
    }

    // const resolveFormType = computed(() => {
    //   let setFormType = ''
    //   if (router.currentRoute.name === 'contract-maker-form-add') {
    //     setFormType = 'add'
    //   } else if (
    //     router.currentRoute.name === 'contract-maker-form-edit'
    //   ) {
    //     setFormType = 'edit'
    //   } else {
    //     setFormType = 'remove'
    //   }

    //   return setFormType
    // })

    // ประกาศตัวแปรแสดงสถานะ Loading
    const overlaySaveButton = ref(false)
    const overlaySubmitButton = ref(false)

    const fncClickUpload = () => {
      refUploadFile.value.$el.childNodes[0].click()
    }

    const fncOnFileChange = e => {
      if (e.target.files) {
        // eslint-disable-next-line prefer-destructuring
        const files = e.target.files
        const fileReader = new FileReader()

        fileReader.addEventListener('load', () => {
          getUrlFileName.value = fileReader.result
        })

        fileReader.readAsDataURL(files[0])
        // eslint-disable-next-line prefer-destructuring
        getFileName.value = files[0]
      }

      isChangeProductImage.value = true
    }

    const fncRemoveFile = () => {
      getInputFileName.value = null
      getUrlFileName.value = null
      getFileName.value = {}
    }

    const fncClickSubmit = action => {
      checkSubmitType.value = action
      refModalConfirmSubmit.value.show()
      isPopupConfirmSubmitActive.value = true
    }

    watch(isPopupConfirmSubmitActive, () => {
      getTextareaSubmitRemark.value = null
    })

    /* ========== ส่วนการทำงานแสดงผล UI ========== */
    /* กำหนดปีเป็น พ.ศ. */
    const toBuddhistYear = (val, format) => {
      const christianYear = val.format('YYYY')
      // eslint-disable-next-line radix
      const buddhishYear = (parseInt(christianYear) + 543).toString()
      return val
        .format(
          format
            .replace('YYYY', buddhishYear)
            .replace('YY', buddhishYear.substring(2, 4)),
        )
        .replace(christianYear, buddhishYear)
    }

    /* กำหนดรูปแบบ ว/ด/ป */
    const resolveFormatDate = val => (val
      ? toBuddhistYear(moment(val), 'DD/MM/YYYY')
      : null)

    const resolvePopupSubmitTypeTitle = computed(() => {
      if (checkSubmitType.value === 'approve') return 'โปรดยืนยันการอนุมัติ'
      if (checkSubmitType.value === 'reject') return 'สาเหตุที่ ไม่อนุมัติ'
      return 'สาเหตุที่ ส่งกลับ'
    })
    /* ========== ส่วนการทำงานแสดงผล UI ========== */

    return {

      getInputCndReMark1,
      getInputCndReMark2,
      getInputCndReMark3,
      getInputCndReMark4,
      getInputCndReMark5,
      getInputCndReMark6,
      getInputCndReMark7,
      getInputCndReMark8,
      getInputCndReMark9,
      getInputCndReMark10,
      getInputSearchFollower,

      getInputRegNum1,
      getInputRegNum2,
      getChassisNum,

      getInputCndRentYear,
      getInputCndRentMonth,
      getInputCndRentAmt1,
      getInputCndRentAmt2,
      getInputCndRentAmt3,
      getInputCndRentAmt4,
      getInputCndRentAmt5,
      getInputCndRentAmt6,
      getInputCndRentAmt7,
      getInputCndRentAmt8,
      getInputCndRentAmt9,
      getInputCndRentAmt10,
      getInputCommAmount,
      getInputTempRent,

      getInputEffStrDate,
      getInputEffEndDate,
      getInputFileName,
      getUrlFileName,
      getFileName,

      getTextareaSubmitRemark,

      // ประกาศตัวแปรสำหรับเก็บค่า
      tempSearchFollower,

      // ประกาศตัวแปร Active / Inactive
      isPopupFollowerActive,
      isPopupConfirmSubmitActive,

      // ประกาศตัวแปร textbox
      followerName,

      // ประกาศตัวแปรสำหรับเก็บค่า
      isChangeProductImage,
      checkSubmitType,

      /* ฟังก์ชั่นเมื่อมีการกดเลือกผู้ดูแลจาก Popup */
      fncSelectFollower,
      fncCancelFollower,
      fncOpenPopupFollower,
      fncEffdate,

      // ตัวแปรกด Tab ถัดไป
      fncTabPrev,

      // ส่วนการทำงานแสดงผล UI
      resolveFormatDate,
      resolvePopupSubmitTypeTitle,

      // fnc การทำงานวันที่ต่างๆ
      fncShowDatepickerEffStrDate,

      fncClickUpload,
      fncOnFileChange,
      fncRemoveFile,

      // ประกาศตัวแปร Ref
      refModalDatePickerEffStrDate,
      refModalConfirmSubmit,
      refUploadFile,

      // ประกาศตัวแปรแสดงสถานะ Loading
      overlaySaveButton,
      overlaySubmitButton,

      fncClickSubmit,
    }
  },

}

</script>

<style>
.v-date-picker-title {
    color: black;
}

.v-picker__title.primary {
  margin-top: -80px;
}

.v-date-picker-table.v-date-picker-table--date.theme--light {
  margin-bottom: -30px;
}

.v-picker.v-card.v-picker--date.theme--light {
  margin-left: 0px;
}

button.v-btn.v-btn--active.v-btn--rounded.theme--light.accent {
  color: blue;
}

button.v-btn.v-size--default.v-date-picker-table__current.v-btn--active.v-btn--text.theme--light.accent {
  color: blue;
}

button.v-btn.v-size--default.v-btn--active.theme--light.accent {
  color: blue;
}

.v-picker__title.primary {
  display: none;
}

.modalDatePicker .modal-body {
  padding: 0px;
}

.modalDatePicker .modal-content {
  width: 280px;
}

.v-picker__body.theme--light {
  width: 280px !important;
}

body.modal-open {
  height: 100vh;
  overflow-y: hidden;
}
</style>
