<template>
  <div>
    <b-modal
      ref="refModalDatePickerIdStrDateGur"
      centered
      title="วันที่ออกบัตร ปชช."
      hide-footer
      size="sm"
      modal-class="modalDatePicker"
    >
      <v-date-picker
        v-model="getInputIdStrDateGur"
        class="mt-6"
        locale="th"
        @input="refModalDatePickerIdStrDateGur.hide()"
      />
    </b-modal>
    <b-modal
      ref="refModalDatePickerIdEndDateGur"
      centered
      title="วันที่หมดอายุบัตร ปชช."
      hide-footer
      size="sm"
      modal-class="modalDatePicker"
    >
      <v-date-picker
        v-model="getInputIdEndDateGur"
        class="mt-6"
        locale="th"
        @input="refModalDatePickerIdEndDateGur.hide()"
      />
    </b-modal>
    <b-modal
      ref="refModalDatePickerBthDateGur"
      centered
      title="วันเกิด"
      hide-footer
      size="sm"
      modal-class="modalDatePicker"
    >
      <v-date-picker
        v-model="getInputBthDateGur"
        class="mt-6"
        locale="th"
        @input="refModalDatePickerBthDateGur.hide()"
      />
    </b-modal>
    <b-row>
      <b-col :cols="12">
        <hr>
        <h3>ข้อมูลผู้ค้ำประกัน</h3>
      </b-col>
    </b-row>

    <b-row>
      <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
        <b-form-group
          label="เลขที่บัตรประชาชน"
          label-for="PsnRegIdnGur"
          label-cols-md="3"
          label-cols-sm="12"
        >
          <template v-slot:label>
            <div style="margin-top:10px;">
              เลขที่บัตรประชาชน
            </div>
          </template>
          <b-form-input
            id="PsnRegIdnGur"
            v-model="getInputPsnRegIdnGur"
            placeholder="เลขที่บัตรประชาชน"
            trim
            autocomplete="off"
            disabled
          />

        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
        <b-form-group
          label-for=""
          label-cols-md="3"
          label-cols-sm="12"
        >
          <template v-slot:label>
            <div style="margin-top:10px;">
              วันที่ออกบัตร ปชช.
            </div>
          </template>
          <b-form-input
            id="InputIdStrDateGur"
            :value="resolveFormatDate(inputIdStrDateGur)"
            trim
            readonly
            placeholder="วันที่ออกบัตร ปชช."
            disabled
            @click="fncShowDatepickerIdStrDateGur()"
          />
        </b-form-group>
      </b-col>

      <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
        <b-form-group
          label-for=""
          label-cols-md="3"
          label-cols-sm="12"
        >
          <template v-slot:label>
            <div style="margin-top:10px;">
              วันที่หมดอายุบัตร ปชช.
            </div>
          </template>
          <b-form-input
            id="InputIdEndDateGur"
            :value="resolveFormatDate(inputIdEndDateGur)"
            trim
            readonly
            placeholder="วันที่หมดอายุบัตร ปชช."
            disabled
            @click="fncShowDatepickerIdEndDateGur()"
          />
        </b-form-group>
      </b-col>
    </b-row>

    <b-row class="delete-margin">
      <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
        <b-form-group
          label-for="cusPsnGurCode"
          label-cols-md="3"
          label-cols-sm="12"
        >
          <template v-slot:label>
            <div style="margin-top:10px;">
              คำนำหน้า
            </div>
          </template>
          <b-input-group v-show="!cusPsnGurCode">
            <b-form-input
              id="cusPsnGurCode"
              v-model="inputSearchCusPsnGur"
              placeholder="คำนำหน้า"
              trim
              @keydown.enter.native="
                fncSearchCusPsnGur(inputSearchCusPsnGur)
              "
            />
          </b-input-group>
          <b-input-group v-show="cusPsnGurCode">
            <b-form-input
              id="cusPsnGurCode"
              :value="cusPsnGurName"
              trim
              disabled
            />
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
        <b-form-group
          label="ชื่อ"
          label-for="CusNamThaGur"
          label-cols-md="3"
          label-cols-sm="12"
        >
          <template v-slot:label>
            <div style="margin-top:10px;">
              ชื่อ
            </div>
          </template>
          <b-form-input
            id="CusNamThaGur"
            v-model="getInputCusNamThaGur"
            placeholder="ชื่อ"
            trim
            autocomplete="off"
            disabled
          />

        </b-form-group>
      </b-col>
      <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
        <b-form-group
          label="นามสกุล"
          label-for="CusSurThaGur"
          label-cols-md="3"
          label-cols-sm="12"
        >
          <template v-slot:label>
            <div style="margin-top:10px;">
              นามสกุล
            </div>
          </template>
          <b-form-input
            id="CusSurThaGur"
            v-model="getInputCusSurThaGur"
            placeholder="นามสกุล"
            trim
            autocomplete="off"
            disabled
          />

        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
        <b-form-group
          label-for=""
          label-cols-md="3"
          label-cols-sm="12"
        >
          <template v-slot:label>
            <div style="margin-top:10px;">
              วันเกิด
            </div>
          </template>
          <b-form-input
            id="InputBthDateGur"
            :value="resolveFormatDate(inputBthDateGur)"
            trim
            readonly
            placeholder="วันเกิด"
            disabled
            @click="fncShowDatepickerBthDateGur()"
          />
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col :cols="12">
        <hr>
        <h3>ที่อยู่ผู้ค้ำประกัน</h3>
      </b-col>
    </b-row>

    <b-row>
      <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
        <b-form-group
          label="บ้านเลขที่"
          label-for="adrDtlln1Gur"
          label-cols-md="3"
          label-cols-sm="12"
        >
          <template v-slot:label>
            <div style="margin-top:10px;">
              บ้านเลขที่
            </div>
          </template>
          <b-form-input
            id="adrDtlln1Gur"
            v-model="getInputAdrDtlln1Gur"
            placeholder="บ้านเลขที่"
            trim
            autocomplete="off"
            disabled
          />

        </b-form-group>
      </b-col>

      <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
        <b-form-group
          label="ตำบล/แขวง"
          label-for="adrDisTrictGur"
          label-cols-md="3"
          label-cols-sm="12"
        >
          <template v-slot:label>
            <div style="margin-top:10px;">
              ตำบล/แขวง
            </div>
          </template>
          <b-form-input
            id="adrDisTrictGur"
            v-model="getInputAdrDisTrictGur"
            placeholder="ตำบล/แขวง"
            trim
            autocomplete="off"
            disabled
          />

        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
        <b-form-group
          label="อำเภอ/เขต"
          label-for="adrAmPhurGur"
          label-cols-md="3"
          label-cols-sm="12"
        >
          <template v-slot:label>
            <div style="margin-top:10px;">
              อำเภอ/เขต
            </div>
          </template>
          <b-form-input
            id="adrAmPhurGur"
            v-model="getInputAdrAmPhurGur"
            placeholder="อำเภอ/เขต"
            trim
            autocomplete="off"
            disabled
          />

        </b-form-group>
      </b-col>

      <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
        <b-form-group
          label="จังหวัด"
          label-for="adrProVinceGur"
          label-cols-md="3"
          label-cols-sm="12"
        >
          <template v-slot:label>
            <div style="margin-top:10px;">
              จังหวัด
            </div>
          </template>
          <b-form-input
            id="adrProVinceGur"
            v-model="getInputAdrProVinceGur"
            placeholder="จังหวัด"
            trim
            autocomplete="off"
            disabled
          />

        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
        <b-form-group
          label="รหัสไปรษณีย์"
          label-for="adrZipCodGur"
          label-cols-md="3"
          label-cols-sm="12"
        >
          <template v-slot:label>
            <div style="margin-top:10px;">
              รหัสไปรษณีย์
            </div>
          </template>
          <b-form-input
            id="adrZipCodGur"
            v-model="getInputAdrZipCodGur"
            placeholder="รหัสไปรษณีย์"
            trim
            autocomplete="off"
            disabled
          />

        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
        <b-form-group
          label="หมายเลขมือถือ"
          label-for="cusPhnNumGur"
          label-cols-md="3"
          label-cols-sm="12"
        >
          <template v-slot:label>
            <div style="margin-top:10px;">
              หมายเลขมือถือ
            </div>
          </template>
          <b-form-input
            id="cusPhnNumGur"
            v-model="getInputCusPhnNumGur"
            placeholder="หมายเลขมือถือ"
            trim
            autocomplete="off"
            disabled
          />

        </b-form-group>
      </b-col>

      <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
        <b-form-group
          label="หมายเลขโทรศัพท์ 2"
          label-for="adrTelNumGur"
          label-cols-md="3"
          label-cols-sm="12"
        >
          <template v-slot:label>
            <div style="margin-top:10px;">
              หมายเลขโทรศัพท์ 2
            </div>
          </template>
          <b-form-input
            id="adrTelNumGur"
            v-model="getInputAdrTelNumGur"
            placeholder="หมายเลขโทรศัพท์ 2"
            trim
            autocomplete="off"
            disabled
          />

        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
        <b-form-group
          label="Email"
          label-for="cusEmailGur"
          label-cols-md="3"
          label-cols-sm="12"
        >
          <template v-slot:label>
            <div style="margin-top:10px;">
              Email
            </div>
          </template>
          <b-form-input
            id="cusEmailGur"
            v-model="getInputCusEmailGur"
            placeholder="Email"
            trim
            autocomplete="off"
            disabled
          />

        </b-form-group>
      </b-col>

      <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
        <b-form-group
          label="Line ID"
          label-for="lineIdGur"
          label-cols-md="3"
          label-cols-sm="12"
        >
          <template v-slot:label>
            <div style="margin-top:10px;">
              Line ID
            </div>
          </template>
          <b-form-input
            id="lineIdGur"
            v-model="getInputLineIdGur"
            placeholder="Line ID"
            trim
            autocomplete="off"
            disabled
          />

        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            variant="primary"
            @click="fncTabPrev()"
          >
            ย้อนกลับ
          </b-button>
          <b-button
            variant="primary"
            style="margin-left: 15px;"
            @click="fncTabNext()"
          >
            ถัดไป
          </b-button>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormInput, BFormGroup, BButton, BInputGroup,
} from 'bootstrap-vue'
import {
  ref, computed,
} from '@vue/composition-api'
import store from '@/store'
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment'
import {
  VDatePicker,
} from 'vuetify/lib'

export default {
  components: {
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BButton,
    BInputGroup,
    VDatePicker,

  },

  props: {
    tabIndex: {
      type: Number,
      required: true,
    },

    resolveFormType: {
      type: String,
      default: null,
    },

    inputPsnRegIdnGur: {
      type: String,
      default: null,
    },

    cusPsnGurCode: {
      type: String,
      default: null,
    },

    cusPsnGurName: {
      type: String,
      default: null,
    },

    inputIdStrDateGur: {
      type: String,
      default: null,
    },

    inputIdEndDateGur: {
      type: String,
      default: null,
    },

    inputBthDateGur: {
      type: String,
      default: null,
    },

    inputEffStrDate: {
      type: String,
      default: null,
    },

    inputEffEndDate: {
      type: String,
      default: null,
    },

    inputCusNamThaGur: {
      type: String,
      default: null,
    },

    inputCusSurThaGur: {
      type: String,
      default: null,
    },

    inputAdrDtlln1Gur: {
      type: String,
      default: null,
    },

    inputAdrDisTrictGur: {
      type: String,
      default: null,
    },

    inputAdrAmPhurGur: {
      type: String,
      default: null,
    },

    inputAdrProVinceGur: {
      type: String,
      default: null,
    },

    inputAdrZipCodGur: {
      type: String,
      default: null,
    },

    inputCusPhnNumGur: {
      type: String,
      default: null,
    },

    inputAdrTelNumGur: {
      type: String,
      default: null,
    },

    inputCusEmailGur: {
      type: String,
      default: null,
    },

    inputLineIdGur: {
      type: String,
      default: null,
    },

    inputRegNum1: {
      type: String,
      default: null,
    },

    inputRegNum2: {
      type: String,
      default: null,
    },

    inputCndRentYear: {
      type: Number,
      default: null,
    },

    inputCndRentMonth: {
      type: Number,
      default: null,
    },

    inputCndRentAmt1: {
      type: Number,
      default: null,
    },

    inputCndRentAmt2: {
      type: Number,
      default: null,
    },

    inputCndRentAmt3: {
      type: Number,
      default: null,
    },

    inputCndRentAmt4: {
      type: Number,
      default: null,
    },

    inputCndRentAmt5: {
      type: Number,
      default: null,
    },

    inputCndRentAmt6: {
      type: Number,
      default: null,
    },

    inputCndRentAmt7: {
      type: Number,
      default: null,
    },

    inputCndRentAmt8: {
      type: Number,
      default: null,
    },

    inputCndRentAmt9: {
      type: Number,
      default: null,
    },

    inputCndRentAmt10: {
      type: Number,
      default: null,
    },

    inputCommAmount: {
      type: Number,
      default: null,
    },

    inputCndReMark1: {
      type: String,
      default: null,
    },

    inputCndReMark2: {
      type: String,
      default: null,
    },

    inputCndReMark3: {
      type: String,
      default: null,
    },

    inputCndReMark4: {
      type: String,
      default: null,
    },

    inputCndReMark5: {
      type: String,
      default: null,
    },

    inputCndReMark6: {
      type: String,
      default: null,
    },

    inputCndReMark7: {
      type: String,
      default: null,
    },

    inputCndReMark8: {
      type: String,
      default: null,
    },

    inputCndReMark9: {
      type: String,
      default: null,
    },

    inputCndReMark10: {
      type: String,
      default: null,
    },
  },

  setup(props, { emit }) {
    const APP_STORE_MODULE_NAME = 'appContractApprove'

    // ======================= 2 WAY BINDING PROPS =======================
    const getInputPsnRegIdnGur = computed({
      get() {
        return props.inputPsnRegIdnGur
      },
      set(val) {
        emit('update:input-psn-reg-idn-gur', val)
      },
    })

    const getCusPsnGurCode = computed({
      get() {
        return props.cusPsnGurCode
      },
      set(val) {
        emit('update:cus-psn-gur-code', val)
      },
    })

    const getCusPsnGurName = computed({
      get() {
        return props.cusPsnGurName
      },
      set(val) {
        emit('update:cus-psn-gur-name', val)
      },
    })

    const getInputIdStrDateGur = computed({
      get() {
        return props.inputIdStrDateGur
      },
      set(val) {
        emit('update:input-id-str-date-gur', val)
      },
    })

    const getInputIdEndDateGur = computed({
      get() {
        return props.inputIdEndDateGur
      },
      set(val) {
        emit('update:input-id-end-date-gur', val)
      },
    })

    const getInputBthDateGur = computed({
      get() {
        return props.inputBthDateGur
      },
      set(val) {
        emit('update:input-bth-date-gur', val)
      },
    })

    const getInputEffStrDate = computed({
      get() {
        return props.inputEffStrDate
      },
      set(val) {
        emit('update:input-eff-str-date', val)
      },
    })

    const getInputEffEndDate = computed({
      get() {
        return props.inputEffEndDate
      },
      set(val) {
        console.log(val)
        emit('update:input-eff-end-date', val)
      },
    })

    const getInputCusNamThaGur = computed({
      get() {
        return props.inputCusNamThaGur
      },
      set(val) {
        emit('update:input-cus-nam-tha-gur', val)
      },
    })

    const getInputCusSurThaGur = computed({
      get() {
        return props.inputCusSurThaGur
      },
      set(val) {
        emit('update:input-cus-sur-tha-gur', val)
      },
    })

    const getInputAdrDtlln1Gur = computed({
      get() {
        return props.inputAdrDtlln1Gur
      },
      set(val) {
        emit('update:input-adr-dtlln1-gur', val)
      },
    })

    const getInputAdrDisTrictGur = computed({
      get() {
        return props.inputAdrDisTrictGur
      },
      set(val) {
        emit('update:input-adr-dis-trict-gur', val)
      },
    })

    const getInputAdrAmPhurGur = computed({
      get() {
        return props.inputAdrAmPhurGur
      },
      set(val) {
        emit('update:input-adr-am-phur-gur', val)
      },
    })

    const getInputAdrProVinceGur = computed({
      get() {
        return props.inputAdrProVinceGur
      },
      set(val) {
        emit('update:input-adr-pro-vince-gur', val)
      },
    })

    const getInputAdrZipCodGur = computed({
      get() {
        return props.inputAdrZipCodGur
      },
      set(val) {
        emit('update:input-adr-zip-cod-gur', val)
      },
    })

    const getInputCusPhnNumGur = computed({
      get() {
        return props.inputCusPhnNumGur
      },
      set(val) {
        emit('update:input-cus-phn-num-gur', val)
      },
    })

    const getInputAdrTelNumGur = computed({
      get() {
        return props.inputAdrTelNumGur
      },
      set(val) {
        emit('update:input-adr-tel-num-gur', val)
      },
    })

    const getInputCusEmailGur = computed({
      get() {
        return props.inputCusEmailGur
      },
      set(val) {
        emit('update:input-cus-email-gur', val)
      },
    })

    const getInputLineIdGur = computed({
      get() {
        return props.inputLineIdGur
      },
      set(val) {
        emit('update:input-line-id-gur', val)
      },
    })

    const getInputRegNum1 = computed({
      get() {
        return props.inputRegNum1
      },
      set(val) {
        emit('update:input-reg-num1', val)
      },
    })

    const getInputRegNum2 = computed({
      get() {
        return props.inputRegNum2
      },
      set(val) {
        emit('update:input-reg-num2', val)
      },
    })

    const getInputCndRentYear = computed({
      get() {
        return props.inputCndRentYear
      },
      set(val) {
        emit('update:input-cnd-rent-year', val)
      },
    })

    const getInputCndRentMonth = computed({
      get() {
        return props.inputCndRentMonth
      },
      set(val) {
        emit('update:input-cnd-rent-month', val)
      },
    })

    const getInputCndRentAmt1 = computed({
      get() {
        return props.inputCndRentAmt1
      },
      set(val) {
        emit('update:input-cnd-rent-amt1', val)
      },
    })

    const getInputCndRentAmt2 = computed({
      get() {
        return props.inputCndRentAmt2
      },
      set(val) {
        emit('update:input-cnd-rent-amt2', val)
      },
    })

    const getInputCndRentAmt3 = computed({
      get() {
        return props.inputCndRentAmt3
      },
      set(val) {
        emit('update:input-cnd-rent-amt3', val)
      },
    })

    const getInputCndRentAmt4 = computed({
      get() {
        return props.inputCndRentAmt4
      },
      set(val) {
        emit('update:input-cnd-rent-amt4', val)
      },
    })

    const getInputCndRentAmt5 = computed({
      get() {
        return props.inputCndRentAmt5
      },
      set(val) {
        emit('update:input-cnd-rent-amt5', val)
      },
    })

    const getInputCndRentAmt6 = computed({
      get() {
        return props.inputCndRentAmt6
      },
      set(val) {
        emit('update:input-cnd-rent-amt6', val)
      },
    })

    const getInputCndRentAmt7 = computed({
      get() {
        return props.inputCndRentAmt7
      },
      set(val) {
        emit('update:input-cnd-rent-amt7', val)
      },
    })

    const getInputCndRentAmt8 = computed({
      get() {
        return props.inputCndRentAmt8
      },
      set(val) {
        emit('update:input-cnd-rent-amt8', val)
      },
    })

    const getInputCndRentAmt9 = computed({
      get() {
        return props.inputCndRentAmt9
      },
      set(val) {
        emit('update:input-cnd-rent-amt9', val)
      },
    })

    const getInputCndRentAmt10 = computed({
      get() {
        return props.inputCndRentAmt10
      },
      set(val) {
        emit('update:input-cnd-rent-amt10', val)
      },
    })

    const getInputCommAmount = computed({
      get() {
        return props.inputCommAmount
      },
      set(val) {
        emit('update:input-comm-amount', val)
      },
    })

    const getInputCndReMark1 = computed({
      get() {
        return props.inputCndReMark1
      },
      set(val) {
        emit('update:input-cnd-re-mark1', val)
      },
    })

    const getInputCndReMark2 = computed({
      get() {
        return props.inputCndReMark2
      },
      set(val) {
        emit('update:input-cnd-re-mark2', val)
      },
    })

    const getInputCndReMark3 = computed({
      get() {
        return props.inputCndReMark3
      },
      set(val) {
        emit('update:input-cnd-re-mark3', val)
      },
    })

    const getInputCndReMark4 = computed({
      get() {
        return props.inputCndReMark4
      },
      set(val) {
        emit('update:input-cnd-re-mark4', val)
      },
    })

    const getInputCndReMark5 = computed({
      get() {
        return props.inputCndReMark5
      },
      set(val) {
        emit('update:input-cnd-re-mark5', val)
      },
    })

    const getInputCndReMark6 = computed({
      get() {
        return props.inputCndReMark6
      },
      set(val) {
        emit('update:input-cnd-re-mark6', val)
      },
    })

    const getInputCndReMark7 = computed({
      get() {
        return props.inputCndReMark7
      },
      set(val) {
        emit('update:input-cnd-re-mark7', val)
      },
    })

    const getInputCndReMark8 = computed({
      get() {
        return props.inputCndReMark8
      },
      set(val) {
        emit('update:input-cnd-re-mark8', val)
      },
    })

    const getInputCndReMark9 = computed({
      get() {
        return props.inputCndReMark9
      },
      set(val) {
        emit('update:input-cnd-re-mark9', val)
      },
    })

    const getInputCndReMark10 = computed({
      get() {
        return props.inputCndReMark10
      },
      set(val) {
        emit('update:input-cnd-re-mark10', val)
      },
    })

    // ประกาศตัวแปร Textbox
    const inputSearchCusPsnGur = ref(null)

    // ประกาศตัวแปร Ref
    const refModalDatePickerIdStrDateGur = ref(null)
    const refModalDatePickerIdEndDateGur = ref(null)
    const refModalDatePickerBthDateGur = ref(null)
    const refModalDatePickerEffStrDate = ref(null)

    // ประกาศตัวแปรสำหรับเก็บค่า
    const tempSearchCusPsnGur = ref(null)

    // ประกาศตัวแปร Active / Inactive
    const isPopupCusPsnGurActive = ref(null)

    /* ฟังก์ชั่นแสดง DatePicker วันที่ออกบัตร ปชช. */
    const fncShowDatepickerIdStrDateGur = () => {
      refModalDatePickerIdStrDateGur.value.show()
    }

    /* ฟังก์ชั่นแสดง DatePicker วันที่หมดอายุบัตร ปชช. */
    const fncShowDatepickerIdEndDateGur = () => {
      refModalDatePickerIdEndDateGur.value.show()
    }

    /* ฟังก์ชั่นแสดง DatePicker วันเกิด */
    const fncShowDatepickerBthDateGur = () => {
      refModalDatePickerBthDateGur.value.show()
    }

    /* ฟังก์ชั่นเมื่อมีการกดเลือกคำนำหน้าจาก Popup */
    const fncOpenPopupCusPsnGur = search => {
      if (search) tempSearchCusPsnGur.value = search
      else tempSearchCusPsnGur.value = null

      isPopupCusPsnGurActive.value = true
    }

    const fncSelectCusPsnGur = data => {
      getCusPsnGurCode.value = data.tabdsctha
      getCusPsnGurName.value = data.tabkeytwo
    }

    const fncSearchCusPsnGur = val => {
      if (val) {
        const payload = {
          action: 'LIST',
          events: '',
          function: 'GET',
          recactcod: null,
          recstscod: null,
          usrcod: null,
          tabkeyone: 'CUSTTLPSN',
          tabkeytwo: val,
        }

        store
          .dispatch(`${APP_STORE_MODULE_NAME}/SP_CONFIG`, payload)
          .then(response => {
            const getSearchResult = response.data.responseData.filter(
              item => item.tabdsctha.toLowerCase().indexOf(val) > -1 || item.tabkeytwo.toLowerCase().indexOf(val) > -1,
            )

            if (getSearchResult.length === 1) {
              getCusPsnGurCode.value = getSearchResult[0].tabkeytwo
              getCusPsnGurName.value = getSearchResult[0].tabdsctha
            } else if ((getSearchResult.length > 1)) {
              fncOpenPopupCusPsnGur(val)
            }
          })
      }
    }

    /* ฟังก์ชั่นยกเลิกคำนำหน้า */
    const fncCancelCusPsnGur = () => {
      getCusPsnGurCode.value = null
      getCusPsnGurName.value = null
      inputSearchCusPsnGur.value = null
    }

    // ตัวแปรกด Tab ถัดไป
    const fncTabNext = () => {
      emit('update:tab-index', Number(props.tabIndex) + 1)
    }

    // ตัวแปรกด Tab ถัดไป
    const fncTabPrev = () => {
      emit('update:tab-index', Number(props.tabIndex) - 1)
    }

    /* ========== ส่วนการทำงานแสดงผล UI ========== */
    /* กำหนดปีเป็น พ.ศ. */
    const toBuddhistYear = (val, format) => {
      const christianYear = val.format('YYYY')
      // eslint-disable-next-line radix
      const buddhishYear = (parseInt(christianYear) + 543).toString()
      return val
        .format(
          format
            .replace('YYYY', buddhishYear)
            .replace('YY', buddhishYear.substring(2, 4)),
        )
        .replace(christianYear, buddhishYear)
    }

    /* กำหนดรูปแบบ ว/ด/ป */
    const resolveFormatDate = val => (val
      ? toBuddhistYear(moment(val), 'DD/MM/YYYY')
      : null)
    /* ========== ส่วนการทำงานแสดงผล UI ========== */

    return {
      getInputPsnRegIdnGur,
      getCusPsnGurCode,
      getCusPsnGurName,

      inputSearchCusPsnGur,
      getInputCusNamThaGur,
      getInputCusSurThaGur,
      getInputAdrDtlln1Gur,
      getInputAdrDisTrictGur,
      getInputAdrAmPhurGur,
      getInputAdrProVinceGur,
      getInputAdrZipCodGur,
      getInputCusPhnNumGur,
      getInputAdrTelNumGur,
      getInputCusEmailGur,
      getInputLineIdGur,
      getInputRegNum1,
      getInputRegNum2,
      getInputCndRentYear,
      getInputCndRentMonth,
      getInputCndRentAmt1,
      getInputCndRentAmt2,
      getInputCndRentAmt3,
      getInputCndRentAmt4,
      getInputCndRentAmt5,
      getInputCndRentAmt6,
      getInputCndRentAmt7,
      getInputCndRentAmt8,
      getInputCndRentAmt9,
      getInputCndRentAmt10,
      getInputCommAmount,
      getInputCndReMark1,
      getInputCndReMark2,
      getInputCndReMark3,
      getInputCndReMark4,
      getInputCndReMark5,
      getInputCndReMark6,
      getInputCndReMark7,
      getInputCndReMark8,
      getInputCndReMark9,
      getInputCndReMark10,

      // ตัวแปรทั้งหมด
      fncSearchCusPsnGur,
      fncOpenPopupCusPsnGur,
      fncSelectCusPsnGur,
      fncCancelCusPsnGur,
      fncTabNext,
      fncTabPrev,

      // ประกาศตัวแปร Active / Inactive
      isPopupCusPsnGurActive,

      // ประกาศตัวแปรสำหรับเก็บค่า
      tempSearchCusPsnGur,

      // วันที่ต่างๆ
      getInputIdStrDateGur,
      getInputIdEndDateGur,
      getInputBthDateGur,
      getInputEffStrDate,
      getInputEffEndDate,

      // fnc การทำงานวันที่ต่างๆ
      fncShowDatepickerIdStrDateGur,
      fncShowDatepickerIdEndDateGur,
      fncShowDatepickerBthDateGur,

      // ประกาศตัวแปร Ref
      refModalDatePickerIdStrDateGur,
      refModalDatePickerIdEndDateGur,
      refModalDatePickerBthDateGur,
      refModalDatePickerEffStrDate,
      // ส่วนการทำงานแสดงผล UI
      resolveFormatDate,

    }
  },

}
</script>

<style>
.v-date-picker-title {
    color: black;
}

.v-picker__title.primary {
  margin-top: -80px;
}

.v-date-picker-table.v-date-picker-table--date.theme--light {
  margin-bottom: -30px;
}

.v-picker.v-card.v-picker--date.theme--light {
  margin-left: 0px;
}

button.v-btn.v-btn--active.v-btn--rounded.theme--light.accent {
  color: blue;
}

button.v-btn.v-size--default.v-date-picker-table__current.v-btn--active.v-btn--text.theme--light.accent {
  color: blue;
}

button.v-btn.v-size--default.v-btn--active.theme--light.accent {
  color: blue;
}

.v-picker__title.primary {
  display: none;
}

.modalDatePicker .modal-body {
  padding: 0px;
}

.modalDatePicker .modal-content {
  width: 280px;
}

.v-picker__body.theme--light {
  width: 280px !important;
}

body.modal-open {
  height: 100vh;
  overflow-y: hidden;
}
</style>
